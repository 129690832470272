<template>
  <div ref="trigger">
    <v-card v-for="i in loaders" :key="i">
      <v-skeleton-loader class="mb-5" type="list-item-avatar-two-line, article, actions"></v-skeleton-loader>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    loaders: {
      default: 1,
      type: Number
    },
    options: {
      type: Object,
      default: () => ({
        root: null,
        threshold: 0
      })
    }
  },
  data () {
    return {
      observer: null
    }
  },
  mounted () {
    const onEntry = entries => {
      if (entries[0].isIntersecting) {
        this.$emit('triggerIntersected')
      }
    }
    this.observer = new IntersectionObserver(onEntry, this.options)
    this.observer.observe(this.$refs.trigger)
  },
  destroyed () {
    this.observer.disconnect()
  }
}
</script>

<style scoped>

</style>
