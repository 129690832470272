<template>
  <div class='onboarding fill-height'>
    <v-responsive :max-width="$route.name === 'selectCategories' ? 720 : 720" class="mx-auto fill-height">
      <v-container class="fill-height">
        <v-row justify="center" align="center">
          <v-col>
            <skeleton v-show="$apollo.queries.currentUser.loading" :loaders="1" />
            <router-view :currentUser="currentUser" v-if="currentUser && currentUser.meta && !$apollo.queries.currentUser.loading" @reFetch="fetchCurrentUser"/>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </div>
</template>
<script>
import Skeleton from '../../../components/Utils/Skeleton'
const CURRENTUSER = require('../../../graphql/currentUser.graphql')
export default {
  apollo: {
    currentUser: {
      query: CURRENTUSER,
      fetchPolicy: 'network-only',
      result (ApolloQueryResult) {
        this.$store.dispatch('setOnboarding', ApolloQueryResult.data.currentUser.meta.onboarding_done)
        this.handleOnboarding()
      }
    }
  },
  methods: {
    async fetchCurrentUser () {
      try {
        return await this.$apollo.queries.currentUser.refetch()
      } catch (e) {
        this.loading = false
        console.log(e)
        this.error = e
      }
    },
    handleOnboarding () {
      if (this.$store.getters.isOnboard && Object.keys(this.currentUser).length) {
        this.$router.replace({ name: 'feed' })
      }
    }
  },
  components: {
    Skeleton
  }
}
</script>
